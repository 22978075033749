/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-08-01 10:42:50
 * @modify date 2022-08-01 10:42:50
 * @desc [description]
 */

import styled from "styled-components";

export const Container = styled.div`
  /**table */
  .ant-collapse {
    .ant-collapse-item {
      border-bottom: 0.5px solid #e0e0e0 !important;
      .ant-collapse-header {
        padding: 17px 10px 17px 0 !important;
      }
      .ant-collapse-content {
        .ant-collapse-content-box {
          padding: 0;
        }
      }
    }
    .ant-collapse-item-active {
      .ti {
        transform: rotate(90deg);
        transition: all 0.4s;
      }
    }
  }
`;

export const HeadContent = styled.div`
  width: 100%;
  min-height: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .text {
    margin: 0 20px 0 4px;
    @media screen and (max-width: 768px) {
      margin: 0 12px 0 3px;
    }
    .spec-q {
      font-family: "IBMPlexSansKR-Bold";
      font-size: 18px;
      line-height: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #bae33d;
      margin-right: 8px;
    }
    .text {
      font-family: "IBMPlexSansKR-Regular";
      font-size: 18px;
      line-height: 24px;
      color: #212121;
    }
  }
  .ti {
    display: block;
    transition: all 0.4s;
  }
`;

export const Content = styled.div`
  padding: 10px 36px;
  background-color: rgba(8, 206, 172, 0.08);
  text-align: left;

  .type {
    font-family: "IBMPlexSansKR-Bold";
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #08ceac;
    margin-right: 7.8px;
  }

  .content {
    font-family: "IBMPlexSansKR-Regular";
    font-size: 18px;
    color: #212121;
  }
`;

export const QuestionIcon = styled.span`
  color: #949494;

  .ti {
    color: #212121;
    transition: all 0.4s;
    display: block;
    &:hover {
      background: initial;
    }
    &.down {
      transform: rotate(90deg);
      transition: all 0.4s;
    }
  }
  &:hover {
    background: initial;
  }
`;

/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-06-16 16:56:20
 * @modify date 2022-06-16 16:56:20
 * @desc [description]
 */

export enum UrlInternal {
  // Name Page
  HOME = "/*",
  LOGIN = "/login",
}

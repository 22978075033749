import React from "react"; //lazy

import { TableFAQ } from "components";
import * as S from "./style";
interface Props {}

const dataFAQ: any = [
  {
    number: "item.id",
    title: "item.title",
    question:
      "item.question 1 orld’s emerging and established artists, we’ve made it easy for new and experienced collectors to discover, buy, and sell art - and so much more. Everything you’ll ever need to collect art, you’ll find on ArtnPrice.",
    answer:
      "item.answer 1 orld’s emerging and established artists, we’ve made it easy for new and experienced collectors to discover, buy, and sell art - and so much more. Everything you’ll ever need to collect art, you’ll find on ArtnPrice.",
  },
  {
    number: "item.id",
    title: "item.title",
    question: "item.question 2",
    answer: "item.answer 2",
  },
  {
    number: "item.id",
    title: "item.title",
    question: "item.question 3",
    answer: "item.answer 3",
  },
  {
    number: "item.id",
    title: "item.title",
    question: "item.question 4",
    answer: "item.answer 4",
  },
];

function Header({}: Props): React.ReactElement {
  return (
    <S.Wrapper>
      <S.Container>
        <S.Title> FAQ</S.Title>
        <S.Content>
          <TableFAQ datas={dataFAQ} />
        </S.Content>
      </S.Container>
    </S.Wrapper>
  );
}

export default Header;

import styled, { css } from "styled-components";

export const Wrapper = styled.div<{ isScroll: any }>`
  width: 100%;
  z-index: 9999;
  position: fixed;
  top: 0;
  height: 100px;
  padding: 0 16px;
  transition: linear 0.5s;

  ${({ isScroll }) =>
    isScroll
      ? css`
          height: 60px;
          transition: linear 0.5s;
          background: #ffffff;
          opacity: 0.95;
        `
      : ""}

  @media screen and (max-width: 768px) {
    height: 80px;
    ${({ isScroll }) =>
      isScroll
        ? css`
            height: 56px;
            background: #ffffff;
          `
        : ""}
  }
`;

export const Header = styled.div<{ isScroll: any }>`
  width: 100%;
  max-width: 1170px;
  height: 100px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: linear 0.5s;
  ${({ isScroll }) =>
    isScroll
      ? css`
          height: 60px;
          transition: linear 0.5s;
        `
      : ""}
  @media screen and (max-width: 768px) {
    height: 80px;
    ${({ isScroll }) =>
      isScroll
        ? css`
            height: 56px;
          `
        : ""}
  }
`;
export const Logo = styled.div`
  width: auto;
  cursor: pointer;
  img {
    height: 40px;
  }
`;

export const Content = styled.div`
  width: auto;
  display: flex;
  gap: 65px;
  color: #000000;
  @media screen and (max-width: 768px) {
    gap: 24px;
  }
  div {
    cursor: pointer;
    font-family: "IBMPlexSansKR-Medium";
    font-style: normal;
    /* font-weight: 600; */
    font-size: 18px;
    line-height: 24px;
    color: #212121;
  }
`;

import React from "react"; //lazy

import GG_Play from "assets/images/company-google-play.png";
import App_Store from "assets/images/company-app-store.png";
import Phone_1 from "assets/images/phone-1.png";
import Phone_2 from "assets/images/phone-2.png";

import * as S from "./style";
interface Props {}

function Company({}: Props): React.ReactElement {
  return (
    <S.Wrapper>
      <S.Container>
        <S.CompanyLeft>
          <S.LeftContent>
            <S.LeftTitle>Determine the value of artwork</S.LeftTitle>
            <S.LeftText>
              The ArtnPrice Database is the art market research tool trusted by
              appraisers and aspiring collectors alike.
            </S.LeftText>
            <S.LeftButton>
              <div className="item">
                <img alt="Google play" src={GG_Play} />
              </div>
              <div className="item">
                <img alt="App store" src={App_Store} />
              </div>
            </S.LeftButton>
          </S.LeftContent>
        </S.CompanyLeft>
        <S.CompanyRight>
          <S.PhoneRight1>
            <img alt="Google play" src={Phone_1} />
          </S.PhoneRight1>
          <S.PhoneRight2>
            <img alt="Google play" src={Phone_2} />
          </S.PhoneRight2>
        </S.CompanyRight>
      </S.Container>
    </S.Wrapper>
  );
}

export default Company;

/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-06-16 17:09:32
 * @modify date 2022-06-16 17:09:32
 * @desc [description]
 */

// Roboto
export const RobotoThin = require("./Roboto-Thin.ttf");
export const IBMPlexSansKRBold = require("./IBMPlexSansKR-Bold.ttf");
export const IBMPlexSansKRExtraLight = require("./IBMPlexSansKR-ExtraLight.ttf");
export const IBMPlexSansKRLight = require("./IBMPlexSansKR-Light.ttf");
export const IBMPlexSansKRMedium = require("./IBMPlexSansKR-Medium.ttf");
export const IBMPlexSansKRRegular = require("./IBMPlexSansKR-Regular.ttf");
export const IBMPlexSansKRSemiBold = require("./IBMPlexSansKR-SemiBold.ttf");
export const IBMPlexSansKRThin = require("./IBMPlexSansKR-Thin.ttf");

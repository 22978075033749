import styled from "styled-components";
import { Layout } from "antd";

export const Footers = styled.div`
  padding: 16px;
  background: #fffcf2;
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1170px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 100px auto;
  @media (max-width: 1024px) {
    margin: 60px auto;
  }
  @media (max-width: 767px) {
    margin: 45px auto;
    flex-direction: column;
  }
  @media (max-width: 425px) {
    margin: 30px auto;
  }
`;

export const Item = styled.div`
  width: auto;
`;

export const Title = styled.div`
  font-family: "IBMPlexSansKR-SemiBold";
  margin-bottom: 18px;
  font-style: normal;
  text-align: left;
  font-size: 24px;
  line-height: 36px;
  color: #212121;
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 12px;
  }
  @media (max-width: 425px) {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 8px;
  }
`;

export const TextContent = styled.div`
  margin-bottom: 8px;
  font-family: "IBMPlexSansKR-Regular";
  font-size: 14px;
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #616161;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 6px;
  }
  @media (max-width: 425px) {
    margin-bottom: 5px;
  }
`;

export const TextNote = styled.div`
  margin-top: 56px;
  font-family: "IBMPlexSansKR-Medium";
  text-align: left;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #616161;
  @media (max-width: 767px) {
    margin-top: 24px;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 24px;
  }
  @media (max-width: 425px) {
    margin-top: 20px;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 20px;
  }
`;

export const Logo = styled.div`
  text-align: left;
  margin-top: 65px;
  cursor: pointer;
  @media (max-width: 767px) {
    margin-top: 24px;
  }
  @media (max-width: 425px) {
    margin-top: 20px;
  }
  img {
    height: 78.8px;
    @media (max-width: 1024px) {
      height: 70px;
    }
    @media (max-width: 768px) {
      height: 60px;
    }
    @media (max-width: 425px) {
      height: 52px;
    }
  }
`;

import React, { useState } from "react"; //lazy

import Logo from "assets/images/logo.png";

import * as S from "./style";
interface Props {
  isScroll?: boolean;
}

function Header({ isScroll }: Props): React.ReactElement {
  const handleClickMenuItem = (id: any) => {
    const element: any = document.getElementById(id);
    element.scrollIntoView({ top: 0, behavior: "smooth" });
  };
  return (
    <S.Wrapper isScroll={isScroll}>
      <S.Header isScroll={isScroll}>
        <S.Logo onClick={() => handleClickMenuItem("company")}>
          <img src={Logo} alt="logo" />
        </S.Logo>
        <S.Content>
          <div onClick={() => handleClickMenuItem("about")}>About us</div>
          <div onClick={() => handleClickMenuItem("faq")}>FAQ</div>
        </S.Content>
      </S.Header>
    </S.Wrapper>
  );
}

export default Header;

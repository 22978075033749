import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 100px 16px;
  background: #fafafa;
  @media screen and (max-width: 768px) {
    padding: 60px 16px;
  }
  @media screen and (max-width: 425px) {
    padding: 40px 16px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
`;

export const Title = styled.div`
  font-family: "IBMPlexSansKR-SemiBold";
  font-style: normal;
  /* font-weight: 600; */
  font-size: 32px;
  line-height: 48px;
  color: #212121;
  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 36px;
  }
  @media screen and (max-width: 425px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const Content = styled.div`
  width: 100%;
`;

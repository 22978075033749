/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-08-01 10:42:35
 * @modify date 2022-08-01 10:42:35
 * @desc [description]
 */

import React, { useEffect, useState } from "react";
import { Table, TableProps, Collapse } from "antd";
// import { clickToTop } from "utils/ScrollToTop";
import moment from "moment-timezone";

import * as S from "./style";

interface Props extends TableProps<any> {
  datas: any;
}
const { Panel }: any = Collapse;

interface PropsQuestion {
  text?: string;
}

const Question = (props: PropsQuestion) => {
  const { text } = props;
  return (
    <S.HeadContent>
      <div className="text">
        <span className="spec-q">Q.</span>
        <span className="text">{text}</span>
      </div>
      <div className="ti ti-arrow-right" />
    </S.HeadContent>
  );
};

function TableMypage(props: Props) {
  const { datas } = props;

  return (
    <S.Container id="table-FAQ">
      {/* <Table
        expandIconColumnIndex={4}
        rowKey={(record: any) => record.key}
        expandedRowKeys={expandedRowKeys}
        onExpand={onTableRowExpand}
        expandRowByClick={true}
        dataSource={datas}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <S.Content>
              <span className="type">A. </span>
              <span
                className="content"
                dangerouslySetInnerHTML={{ __html: record?.answer }}
              ></span>
            </S.Content>
          ),
          // columnWidth: "0",
          expandIcon: ({ expanded, onExpand, record }) => {
            return (
              <S.QuestionIcon
                onClick={(e: any) => {
                  onTableRowExpand(expanded, record);
                  onExpand(record, e);
                }}
              >
                <span
                  className={`ti ti-arrow-right ${!expanded ? "" : "down"}`}
                ></span>
              </S.QuestionIcon>
            );
          },
        }}
      /> */}
      <Collapse
        bordered={false}
        defaultActiveKey={["-10"]}
        // expandIcon={({ isActive }) => (
        //   <div className={`ti ti-arrow-right ${isActive ? "" : "down"}`} />
        // )}
      >
        {datas.map((v: any, i: number) => {
          return (
            <Panel
              header={<Question text={v.question} />}
              key={i}
              showArrow={false}
            >
              <S.Content>
                <span className="type">A. </span>
                <span
                  className="content"
                  dangerouslySetInnerHTML={{ __html: v?.answer }}
                ></span>
              </S.Content>
            </Panel>
          );
        })}
      </Collapse>
    </S.Container>
  );
}

export default TableMypage;

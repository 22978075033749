import React from "react"; //lazy
import Slider from "react-slick";

import Image_1_1 from "assets/images/image-1-1.png";
import Image_2_1 from "assets/images/image-2-1.png";
import Image_2_2 from "assets/images/image-2-2.png";
import Image_3_1 from "assets/images/image-3-1.png";
import Image_4_1 from "assets/images/image-4-1.png";
import Image_4_2 from "assets/images/image-4-2.png";

import * as S from "./style";
interface Props {}

const image = [
  [Image_1_1],
  [Image_2_1, Image_2_2],
  [Image_3_1],
  [Image_4_1, Image_4_2],
];
const dataImage = [...image, ...image, ...image, ...image, ...image, ...image];

function Header({}: Props): React.ReactElement {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 12,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    speed: 8000,
    autoplaySpeed: 10,
    pauseOnHover: false,
    cssEase: "linear",
  };

  return (
    <S.Wrapper>
      <S.Container>
        <S.Title>Future of Art Collecting</S.Title>
        <S.Content>
          <S.BoxImage widthElement={window.innerWidth}>
            <Slider {...settings}>
              {dataImage.map((v: any, index: number) => {
                return (
                  <S.SlideItem key={index}>
                    {v.map((u: any, i: number) => {
                      return <img key={`${index}-${i}`} alt="img" src={u} />;
                    })}
                  </S.SlideItem>
                );
              })}
            </Slider>
          </S.BoxImage>
        </S.Content>
        <S.Line>
          <div className="line" />
        </S.Line>
        <S.Text>
          <div className="title">ArtnPrice is for art collecting.</div>
          <div className="text">
            As the leading marketplace for art by the world’s emerging and
            established artists, we’ve made it easy for new and experienced
            collectors to discover, buy, and sell art - and so much more.
            Everything you’ll ever need to collect art, you’ll find on
            ArtnPrice.
          </div>
        </S.Text>
      </S.Container>
    </S.Wrapper>
  );
}

export default Header;

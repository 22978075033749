/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-26 11:42:50
 * @modify date 2022-01-26 11:42:50
 * @desc [description]
 */

import React, { lazy, useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import "antd/dist/antd.css";

/** Common */
import { UrlInternal } from "common/constants/endpoints";
import GlobalStyles from "common/style/GlobalStyle";
import Normalize from "common/style/Normalize";
import defaultTheme from "common/style/themes/default";

/** Router Global */
import { PublicRoute, PrivateRoute } from "router"; //PrivateRoute,
import { FooterPage } from "components";

/** Utils */
import ScrollToTop from "utils/ScrollToTop";
import { useDispatch } from "react-redux";
import { getLocalStorage } from "utils/localStorage";
import { getDataUser } from "store/actions/getUserAction";

/** Page */
const Home = lazy(() => import("pages/Home"));
const PageNotFound = lazy(() => import("pages/PageNotFound"));

// console.log("getDataById", a);

const App: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    getLocalStorage({ typeGetData: "user" }).then((v: any) => {
      if (v) dispatch(getDataUser());
    });
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Normalize />
      <GlobalStyles />
      <Router>
        <ScrollToTop />
        <Switch>
          <PublicRoute exact path={UrlInternal.HOME} component={Home} />
          <PublicRoute exact path={"*"} component={PageNotFound} />
        </Switch>
        <FooterPage />
      </Router>
    </ThemeProvider>
  );
};

export default App;

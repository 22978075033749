import styled from "styled-components";
import BG_Company from "assets/images/bg-company.png";
import BG_Company_MB from "assets/images/bg-company-mb.png";

export const Wrapper = styled.div`
  width: 100%;
  padding: 100px 16px 0;
  display: flex;
  justify-content: center;

  background-image: url(${BG_Company});
  background-position: revert;
  background-repeat: round;
  background-size: cover;
  @media screen and (max-width: 768px) {
    padding: 80px 16px 0;
    background-image: url(${BG_Company_MB});
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1170px;
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CompanyLeft = styled.div`
  width: 50%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
export const LeftContent = styled.div`
  width: 100%;
  max-width: 480px;
  padding: 243px 16px 342px 0;
  @media screen and (max-width: 768px) {
    margin: 0 auto;
    padding: 60px 16px;
    max-width: 380px;
  }
  @media screen and (max-width: 425px) {
    padding: 20px 0;
  }
`;
export const LeftTitle = styled.div`
  width: 100%;
  font-family: "IBMPlexSansKR-SemiBold";
  font-style: normal;
  /* font-weight: 600; */
  font-size: 48px;
  line-height: 52px;
  color: #000000;
  @media screen and (max-width: 768px) {
    font-size: 36px;
    line-height: 44px;
  }
  @media screen and (max-width: 425px) {
    font-size: 30px;
    line-height: 40px;
  }
`;
export const LeftText = styled.div`
  width: 100%;
  margin-top: 19px;
  font-family: "IBMPlexSansKR-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #616161;
  @media screen and (max-width: 768px) {
    margin-top: 12px;
    font-size: 14px;
    line-height: 20px;
  }
`;
export const LeftButton = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  gap: 12px;
  @media screen and (max-width: 768px) {
    margin-top: 16px;
  }
  @media screen and (max-width: 425px) {
    margin-top: 16px;
    justify-content: center;
  }
  .item {
    cursor: pointer;
    /* @media screen and (max-width: 425px) {
      width: 160px;
      height: 42px;
    } */
    img {
      width: auto;
      height: 40px;
      @media screen and (max-width: 425px) {
        width: 140px;
        height: 42px;
      }
    }
  }
`;

export const CompanyRight = styled.div`
  width: 50%;
  position: relative;
  @media screen and (max-width: 768px) {
    width: 100%;
    overflow-x: hidden;
  }
`;
export const PhoneRight1 = styled.div`
  /* position: absolute; */
  z-index: 1;
  margin: 130px 0 0 20px;
  img {
    max-width: 247px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 60px 0;
    display: flex;
    justify-content: center;
    img {
      margin-left: -25%;
      width: 50%;
      max-width: 250px;
    }
  }
  @media screen and (max-width: 425px) {
    margin: 50px 0;
    img {
      /* margin-left: -20%; */
      width: 60%;
    }
  }
`;
export const PhoneRight2 = styled.div`
  position: absolute;
  z-index: 2;
  top: 60px;
  left: 190px;
  img {
    max-width: 247px;
  }
  @media screen and (max-width: 1024px) {
    left: 140px;
  }
  @media screen and (max-width: 768px) {
    top: 0;
    margin-left: auto;
    margin-right: auto;
    left: 25%;
    right: -25%;
    text-align: center;
    img {
      margin-left: -25%;
      width: 50%;
      max-width: 250px;
    }
  }
  @media screen and (max-width: 425px) {
    top: 10px;
    margin-left: auto;
    margin-right: auto;
    left: 25%;
    right: -25%;
    text-align: center;
    img {
      /* margin-left: -20%; */
      width: 60%;
    }
  }
`;

export const Footer = styled.div`
  width: 100%;
`;

import React from "react"; //lazy
import Logo from "assets/images/logo.png";

// import { ArrowUpOutlined } from "@ant-design/icons";

// import { UrlInternal } from "common/constants/endpoints";

import * as S from "./style";

interface Props {
  className?: any;
}

const FooterPage = ({ className }: Props) => {
  const handleClickMenuItem = () => {
    const element: any = document.getElementById("company");
    element.scrollIntoView({ top: 0, behavior: "smooth" });
  };

  return (
    <S.Footers style={{ textAlign: "center" }} className={className}>
      <S.Container>
        <S.Item>
          <S.Title>COMPANY</S.Title>
          <S.TextContent>㈜열매컴퍼니</S.TextContent>
          <S.TextContent>대표자: 김재욱</S.TextContent>
          <S.TextContent>
            주소: 06182 서울 강남구 영동대로 407, 코니빌딩 4층
          </S.TextContent>
          <S.TextContent>
            31470 충청남도 아산시 배방읍 희망로46번길 45-7, 406호
          </S.TextContent>
          <S.TextContent>사업자등록번호: 664-88-00573</S.TextContent>
          <S.TextNote>© Yeolmaecompany. all rights reserved.</S.TextNote>
        </S.Item>
        <S.Item>
          <S.Title>CONTACT</S.Title>
          <S.TextContent>02-514-0772 / 070-5015-0772</S.TextContent>
          <S.TextContent>평일-10:00~18:00</S.TextContent>
          <S.TextContent>점심시간-12:00~13:00</S.TextContent>
          <S.Logo onClick={handleClickMenuItem}>
            <img alt="logo" src={Logo} />
          </S.Logo>
        </S.Item>
      </S.Container>
    </S.Footers>
  );
};

export default FooterPage;

import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 100px 0;
  @media screen and (max-width: 768px) {
    padding: 60px 0;
  }
  @media screen and (max-width: 425px) {
    padding: 40px 0;
  }
`;
export const Container = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 0 16px;
`;

export const Title = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  font-family: "IBMPlexSansKR-SemiBold";
  font-style: normal;
  /* font-weight: 600; */
  font-size: 36px;
  line-height: 52px;
  color: #212121;
  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 44px;
  }
  @media screen and (max-width: 425px) {
    font-size: 29.8px;
    line-height: 40px;
  }
`;
export const Content = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: 40px auto 0;

  @media screen and (max-width: 768px) {
    margin: 32px auto 0;
  }
  @media screen and (max-width: 425px) {
    margin: 24px auto 0;
  }
`;
export const BoxImage = styled.div<{ widthElement: any }>`
  /* padding: 0 16px 20px 0; */
  width: 3650px;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  @media screen and (max-width: 768px) {
    width: 2700px;
  }
  @media screen and (max-width: 425px) {
    width: 2250px;
  }
  .slick-slider {
    /* .slick-dots {
      display: none !important;
    } */
  }
`;
export const SlideItem = styled.div`
  width: 270px;
  @media screen and (max-width: 768px) {
    width: 200px;
  }
  @media screen and (max-width: 425px) {
    width: 170px;
  }
  img {
    width: 270px;
    margin-bottom: 30px;
    @media screen and (max-width: 768px) {
      width: 200px;
      margin-bottom: 20px;
    }
    @media screen and (max-width: 425px) {
      width: 170px;
      margin-bottom: 10px;
    }
  }
`;

export const Line = styled.div`
  width: 100%;
  margin: 40px 0;
  @media screen and (max-width: 768px) {
    margin: 30px 0;
  }
  @media screen and (max-width: 425px) {
    margin: 20px 0;
  }
  .line {
    margin: 0 auto;
    width: 1.5px;
    height: 130px;
    background-color: #000000;
    @media screen and (max-width: 768px) {
      height: 100px;
    }
    @media screen and (max-width: 425px) {
      height: 70px;
    }
  }
`;

export const Text = styled.div`
  width: 100%;
  max-width: 930px;
  margin: 0 auto;
  .title {
    width: 100%;
    font-family: "IBMPlexSansKR-SemiBold";
    font-style: normal;
    /* font-weight: 600; */
    font-size: 32px;
    line-height: 48px;
    color: #212121;
    text-align: center;
    @media screen and (max-width: 768px) {
      font-size: 26px;
      line-height: 36px;
    }
    @media screen and (max-width: 425px) {
      font-size: 23.5px;
      line-height: 30px;
      text-align: left;
    }
  }
  .text {
    width: 100%;
    margin-top: 8px;
    font-family: "IBMPlexSansKR-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #616161;
    text-align: center;
    @media screen and (max-width: 768px) {
      font-size: 16px;
      line-height: 28px;
    }
    @media screen and (max-width: 425px) {
      font-size: 15px;
      line-height: 20px;
      text-align: left;
    }
  }
`;
